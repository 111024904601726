import {cleanMoneyString, formatNumber} from "./Utils";

const createMoneyInput = () => {

    const moneyInput = document.querySelectorAll('.input-money');

    moneyInput.forEach(input => {

        const actualID = input.id.slice(0, -6);
        const actualInput = document.getElementById(actualID)
        const actualValue = actualInput.value
        if(actualValue) {
            input.value = formatNumber(actualValue)
        }

        // Create input
        input.addEventListener('keydown', e => {

            // tab, esc, enter
            if (inArray(e.keyCode, [9, 27, 13]) ||
                // Ctrl+A
                (e.keyCode === 65 && e.ctrlKey === true) ||
                // home, end, left, right, down, up
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }

            // Block all others
            e.preventDefault();

            //Set allowed keys
            const n = ["1","2","3","4","5","6","7","8","9","0"];

            //Get current input value
            const current_value = input.value;

            //Just get numbers
            const clean = cleanMoneyString(current_value);

            //Get current input
            let keyCode = e.keyCode || e.which;
            if (keyCode >= 96 && keyCode <= 105) {
                // Numpad keys
                keyCode -= 48;
            }
            const charCode = String.fromCharCode(keyCode);

            // allow backspace & del
            if(inArray(e.keyCode,[8,46])){
                //const deleteLast = current_value.slice(0, -1)

                const deletedValue = clean.slice(0, -1)

                //Set actual value
                actualInput.value = deletedValue

                //Set _dummy value
                if(deletedValue > 0) {
                    input.value = formatNumber(deletedValue);
                } else {
                    input.value = "";
                }
                input.dispatchEvent(new Event('input'));


                //Stop any further formatting
                return;
            }

            // If input is a number
            if(n.includes(charCode))
            {
                const value = clean + charCode;

                //Set actual value
                actualInput.value = value;

                //Get formatted value
                input.value = formatNumber(value);
                input.dispatchEvent(new Event('input'));
            }

        })
    })
}

window.addEventListener('load', createMoneyInput);

const inArray = ( needle, haystack ) => haystack.indexOf(needle) !== -1;




